<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Ganancias</b-card-title>
    </b-card-header>

    <b-card-body class="pb-0">
      <div class="d-flex justify-content-start mb-3">
        <div class="mr-2">
          <b-card-text class="mb-50"> Este mes </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">$ </sup>
            <span class="text-primary">{{ formatMoney(revenue.estemes) }}</span>
          </h3>
        </div>
        <div>
          <b-card-text class="mb-50"> Mes anterior </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">$ </sup>
            <span>{{ formatMoney(revenue.mesanterior) }}</span>
          </h3>
        </div>
      </div>

      <!-- apex chart -->
      <vue-apex-charts type="line" height="240" :series="revenueComparisonLine.series"
        :options="revenueComparisonLine.chartOptions" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCardHeader,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardHeader,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    this.revenue = this.data.revenue;
    this.revenueComparisonLine.series = this.data.series;
  },
  data() {
    return {
      revenue: {},
      revenueComparisonLine: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            offsetX: -10,
          },
          stroke: {
            curve: "smooth",
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ["#d0ccff", "#ebe9f1"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              inverseColors: false,
              gradientToColors: [$themeColors.primary, "#ebe9f1"],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "1rem",
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ["01", "05", "09", "13", "17", "21", "26", "31"],
            axisBorder: {
              show: false,
            },
            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "1rem",
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}k` : val;
              },
            },
          },
          grid: {
            borderColor: "#e7eef7",
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
    };
  },
  methods: {
    formatMoney(number, decPlaces, decSep, thouSep) {
      decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSep = typeof decSep === "undefined" ? "." : decSep;
      thouSep = typeof thouSep === "undefined" ? "," : thouSep;
      const sign = number < 0 ? "-" : "";
      let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
      let j = (j = i.length) > 3 ? j % 3 : 0;
      return sign +
        (j ? i.substring(0, j) + thouSep : "") +
        i.substring(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
    },
  }
};
</script>
