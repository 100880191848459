var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data ? _c('b-card', {
    staticClass: "card",
    class: _vm.isMarket ? 'card-congratulations2' : 'card-congratulations',
    attrs: {
      "text-variant": "center"
    }
  }, [_c('b-img', {
    staticClass: "congratulations-img-left",
    attrs: {
      "src": require('@/assets/images/elements/decore-left.png')
    }
  }), _c('b-img', {
    staticClass: "congratulations-img-right",
    attrs: {
      "src": require('@/assets/images/elements/decore-right.png')
    }
  }), _c('b-avatar', {
    staticClass: "shadow mb-2",
    attrs: {
      "variant": "dark",
      "size": "70"
    }
  }, [_vm.store !== 0 || _vm.warehouse !== 0 ? _c('b-img', {
    attrs: {
      "src": _vm.data.logo,
      "width": "70",
      "height": "70"
    }
  }) : _vm._e()], 1), _c('h4', {
    staticClass: "mb-1 mt-50 text-white"
  }, [_vm._v(" Bienvenido " + _vm._s(_vm.data.nombreUser) + " " + _vm._s(_vm.data.apellidoUser) + " ")]), _vm.store !== 0 || _vm.warehouse !== 0 ? _c('b-card-text', {
    staticClass: "m-auto w-75"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.tienda))])]) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }