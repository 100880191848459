<template>
  <b-overlay :show="loading" class="mh" rounded="sm">
    <section v-if="data !== null" id="dashboard-analytics">
      <b-row>
        <b-col lg="5" md="12">
          <welcome :data="data.bienvenidas[0]" :is-market="isMarket" :warehouse="warehouseID" :store="storeID" />
        </b-col>
        <b-col lg="7" md="12">
          <sales :data="data.ventas[0]" />
        </b-col>
        <b-col lg="5" md="12">
          <orders :data="data.ordenes[0]" />
        </b-col>
        <b-col lg="7" md="12">
          <compare-months :data="data.comparativaordenes[0]" />
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import Sales from "./Sales.vue";
import Orders from "./Orders.vue";
import Welcome from "./Welcome.vue";
import CompareMonths from "./CompareMonth.vue";
import { BRow, BCol, BOverlay } from "bootstrap-vue";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("app");

export default {
  components: {
    BRow,
    BCol,
    Sales,
    Orders,
    Welcome,
    BOverlay,
    CompareMonths,
  },
  computed: {
    ...mapState(["storeSelected"]),
    ...mapState(["warehouseSelected"]),
  },
  data() {
    return {
      data: null,
      storeID: 0,
      loading: true,
      isMarket: true,
      warehouseID: 0,
    };
  },
  mounted() {
    this.onStoreSelectedChange();
    this.onWarehouseSelectedChange();
  },
  beforeDestroy() {
    this.unwatchStore();
    this.unwatchWarehouse();
  },
  methods: {
    loadDashboardData() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/Dashboard",
          body: JSON.stringify({
            Market: this.isMarket,
            IDBodega: this.warehouseID,
            tokenSesion: getAuthToken(),
            IDEmpresaAsociada: this.storeID,
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.data = null;
            this.$nextTick(() => {
              this.data = res.data;
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error [${e.message}].`,
              title: "Error",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onStoreSelectedChange() {
      this.unwatchStore = this.$store.watch(
        (_, g) => {
          return g["app/storeSelected"];
        },
        (storeID, __) => {
          if (storeID !== undefined && storeID !== null) {
            this.storeID = storeID;
            this.isMarket = false;
            this.warehouseID = 0;
            this.loadDashboardData();
          }
        }
      );
    },
    onWarehouseSelectedChange() {
      this.unwatchWarehouse = this.$store.watch(
        (_, g) => {
          return g["app/warehouseSelected"];
        },
        (warehouseID, __) => {
          if (warehouseID !== undefined && warehouseID !== null) {
            this.storeID = 0;
            this.isMarket = true;
            this.warehouseID = warehouseID;
            this.loadDashboardData();
          }
        }
      );
    },
  },
};
</script>
<style>
.mh {
  min-height: 80vh;
}
</style>