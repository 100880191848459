<template>
  <b-card v-if="data" class="card" :class="isMarket ? 'card-congratulations2' : 'card-congratulations'"
    text-variant="center">
    <!-- images -->
    <b-img :src="require('@/assets/images/elements/decore-left.png')" class="congratulations-img-left" />
    <b-img :src="require('@/assets/images/elements/decore-right.png')" class="congratulations-img-right" />
    <!--/ images -->

    <b-avatar variant="dark" size="70" class="shadow mb-2">
      <b-img :src="data.logo" width="70" height="70" v-if="store !== 0 || warehouse !== 0"/>
    </b-avatar>
    <h4 class="mb-1 mt-50 text-white">
      Bienvenido {{ data.nombreUser }} {{ data.apellidoUser }}
    </h4>
    <b-card-text class="m-auto w-75" v-if="store !== 0 || warehouse !== 0">
      <strong>{{ data.tienda }}</strong>
    </b-card-text>
  </b-card>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText } from "bootstrap-vue";

export default {

  components: {
    BImg,
    BCard,
    BAvatar,
    BCardText,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isMarket: {
      type: Boolean,
      required: true,
    },
    store: {
      type: Number,
      required: true,
    },
    warehouse: {
      type: Number,
      required: true,
    },
  },
};
</script>
