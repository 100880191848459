var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Ordenes")]), _c('b-dropdown', {
    staticClass: "chart-dropdown",
    attrs: {
      "text": "Este mes",
      "variant": "transparent",
      "right": "",
      "no-caret": "",
      "toggle-class": "p-0",
      "size": "sm",
      "disabled": ""
    }
  }, [_c('b-dropdown-item', [_vm._v(" Esta semana ")])], 1)], 1), _c('b-card-body', [_c('vue-apex-charts', {
    attrs: {
      "type": "radialBar",
      "height": "325",
      "series": _vm.ordenesData.series,
      "options": _vm.ordenesData.chartOptions
    }
  }), _vm._l(_vm.data.chartInfo, function (data, key) {
    return _c('div', {
      key: key,
      staticClass: "d-flex justify-content-between"
    }, [_c('div', {
      staticClass: "series-info d-flex align-items-center"
    }, [_c('feather-icon', {
      class: key === 'entregadas' ? 'text-primary' : key === 'pendientes' ? 'text-warning' : 'text-danger',
      attrs: {
        "icon": "CircleIcon",
        "size": "16"
      }
    }), _c('span', {
      staticClass: "font-weight-bold text-capitalize ml-75"
    }, [_vm._v(_vm._s(key))])], 1), _c('span', [_vm._v(_vm._s(data))])]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }