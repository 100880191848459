<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Ordenes</b-card-title>
      <b-dropdown text="Este mes" variant="transparent" class="chart-dropdown" right no-caret toggle-class="p-0"
        size="sm" disabled>
        <b-dropdown-item> Esta semana </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <vue-apex-charts type="radialBar" height="325" :series="ordenesData.series" :options="ordenesData.chartOptions" />

      <div v-for="(data, key) in data.chartInfo" :key="key" class="d-flex justify-content-between">
        <div class="series-info d-flex align-items-center">
          <feather-icon icon="CircleIcon" size="16" :class="key === 'entregadas'
              ? 'text-primary'
              : key === 'pendientes'
                ? 'text-warning'
                : 'text-danger'
            " />
          <span class="font-weight-bold text-capitalize ml-75">{{ key }}</span>
        </div>

        <span>{{ data }}</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BDropdown,
  BCardBody,
  BCardTitle,
  BCardHeader,
  BDropdownItem,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    BCard,
    BDropdown,
    BCardBody,
    BCardTitle,
    BCardHeader,
    VueApexCharts,
    BDropdownItem,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    this.ordenesData.series = this.data.series;
  },
  data() {
    return {
      ordenesData: {
        series: [],
        chartOptions: {
          labels: ["Finished", "Pending", "Rejected"],
          plotOptions: {
            radialBar: {
              size: 150,
              hollow: {
                size: "20%",
              },
              track: {
                strokeWidth: "100%",
                margin: 15,
              },
              dataLabels: {
                value: {
                  fontSize: "1rem",
                  colors: "#5e5873",
                  fontWeight: "500",
                  offsetY: 5,
                },
                total: {
                  show: true,
                  label: "Total",
                  colors: "#5e5873",
                  fontSize: "1.286rem",
                  fontWeight: "500",
                  formatter: (val) => {
                    if (this.data.series.some(s => s > 0))
                      return (100.0).toFixed(1) + "%";
                    return (0.0).toFixed(1) + "%";
                  },
                },
              },
            },
          },
          colors: [
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.danger,
          ],
          stroke: {
            lineCap: "round",
          },
          chart: {
            height: 355,
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
        },
      },
    };
  },
};
</script>
