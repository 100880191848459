var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    staticClass: "mh",
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    }
  }, [_vm.data !== null ? _c('section', {
    attrs: {
      "id": "dashboard-analytics"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "5",
      "md": "12"
    }
  }, [_c('welcome', {
    attrs: {
      "data": _vm.data.bienvenidas[0],
      "is-market": _vm.isMarket,
      "warehouse": _vm.warehouseID,
      "store": _vm.storeID
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "7",
      "md": "12"
    }
  }, [_c('sales', {
    attrs: {
      "data": _vm.data.ventas[0]
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "5",
      "md": "12"
    }
  }, [_c('orders', {
    attrs: {
      "data": _vm.data.ordenes[0]
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "7",
      "md": "12"
    }
  }, [_c('compare-months', {
    attrs: {
      "data": _vm.data.comparativaordenes[0]
    }
  })], 1)], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }