var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Estadísticas")]), _c('b-dropdown', {
    staticClass: "chart-dropdown",
    attrs: {
      "text": "Este mes",
      "variant": "transparent",
      "right": "",
      "no-caret": "",
      "toggle-class": "p-0",
      "size": "sm",
      "disabled": ""
    }
  }, [_c('b-dropdown-item', [_vm._v(" Esta semana ")])], 1)], 1), _c('b-card-body', {
    staticClass: "statistics-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "sfd"
  }, [_c('b-avatar', {
    staticClass: "mb-1",
    attrs: {
      "size": "48",
      "variant": "light-primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "TrendingUpIcon"
    }
  })], 1), _c('h6', {
    staticClass: "font-weight-bolder"
  }, [_vm._v("Ventas")]), _c('b-card-text', [_vm._v(" " + _vm._s(_vm.data.ventaLocal) + " ")])], 1)]), _c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "sfd"
  }, [_c('b-avatar', {
    staticClass: "mb-1",
    attrs: {
      "size": "48",
      "variant": "light-info"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "UserIcon"
    }
  })], 1), _c('h6', {
    staticClass: "font-weight-bolder"
  }, [_vm._v("Clientes")]), _c('b-card-text', [_vm._v(" " + _vm._s(_vm.data.cantidadUserCOmpra) + " ")])], 1)]), _c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "sfd"
  }, [_c('b-avatar', {
    staticClass: "mb-1",
    attrs: {
      "size": "48",
      "variant": "light-danger"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "BoxIcon"
    }
  })], 1), _c('h6', {
    staticClass: "font-weight-bolder"
  }, [_vm._v("Productos")]), _c('b-card-text', [_vm._v(" " + _vm._s(_vm.data.cantidadProductos) + " ")])], 1)]), _c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "sfd"
  }, [_c('b-avatar', {
    staticClass: "mb-1",
    attrs: {
      "size": "48",
      "variant": "light-dark"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "DollarSignIcon"
    }
  })], 1), _c('h6', {
    staticClass: "font-weight-bolder"
  }, [_vm._v("Ganancias")]), _c('b-card-text', [_vm._v(" " + _vm._s(_vm.data.ganacias) + " ")])], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }