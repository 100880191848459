<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Estadísticas</b-card-title>
      <b-dropdown
        text="Este mes"
        variant="transparent"
        class="chart-dropdown"
        right
        no-caret
        toggle-class="p-0"
        size="sm"
        disabled
      >
        <b-dropdown-item> Esta semana </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col md="3" sm="6" class="mb-2 mb-md-0">
          <div class="sfd">
            <b-avatar size="48" variant="light-primary" class="mb-1">
              <feather-icon size="24" icon="TrendingUpIcon" />
            </b-avatar>
            <h6 class="font-weight-bolder">Ventas</h6>
            <b-card-text>
              {{ data.ventaLocal }}
            </b-card-text>
          </div>
        </b-col>
        <b-col md="3" sm="6" class="mb-2 mb-md-0">
          <div class="sfd">
            <b-avatar size="48" variant="light-info" class="mb-1">
              <feather-icon size="24" icon="UserIcon" />
            </b-avatar>
            <h6 class="font-weight-bolder">Clientes</h6>
            <b-card-text>
              {{ data.cantidadUserCOmpra }}
            </b-card-text>
          </div>
        </b-col>

        <b-col md="3" sm="6" class="mb-2 mb-md-0">
          <div class="sfd">
            <b-avatar size="48" variant="light-danger" class="mb-1">
              <feather-icon size="24" icon="BoxIcon" />
            </b-avatar>
            <h6 class="font-weight-bolder">Productos</h6>
            <b-card-text>
              {{ data.cantidadProductos }}
            </b-card-text>
          </div>
        </b-col>

        <b-col md="3" sm="6" class="mb-2 mb-md-0">
          <div class="sfd">
            <b-avatar size="48" variant="light-dark" class="mb-1">
              <feather-icon size="24" icon="DollarSignIcon" />
            </b-avatar>
            <h6 class="font-weight-bolder">Ganancias</h6>
            <b-card-text>
              {{ data.ganacias }}
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BMedia,
  BAvatar,
  BDropdown,
  BCardBody,
  BCardText,
  BMediaBody,
  BCardTitle,
  BCardHeader,
  BMediaAside,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BMedia,
    BAvatar,
    BDropdown,
    BCardText,
    BCardBody,
    BMediaBody,
    BCardTitle,
    BCardHeader,
    BMediaAside,
    BDropdownItem,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
.sfd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
